/* eslint-disable max-lines */
import { getAxiosRequest } from '@swarmion/serverless-contracts';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { FromSchema } from 'json-schema-to-ts';

import {
  getUsersFromOrg,
  OrganizationUsers,
  StatusResponseTypes,
  SUCCESS,
} from '@dialog/auth-contracts';
import {
  AiConfigWithFiles,
  BillingData,
  Collections,
  ConversationsClusterUrl,
  createOrganizationContract,
  createShopifyOrganizationsContract,
  getAiConfigContract,
  getBillingDataContract,
  getCollectionsContract,
  getConversationsClusterUrlContract,
  getMetafieldsContract,
  getMetricsContract,
  getOrganizationContract,
  getProductsContract,
  getUploadDocumentUrlContract,
  Metafields,
  Metrics,
  MetricsOutput,
  MetricsPeriod,
  OnboardingInformations,
  Organization,
  organizationExistsContract,
  ProductsResults,
  saveOnboardingContract,
  ShopifyOrganization,
  updateAiConfigContract,
  updateOrganizationContract,
  UpdateOrganizationRequest,
  UploadDocumentUrlResponse,
  uploadLogoContract,
  uploadProductInventoryContract,
  UploadProductInventoryResponse,
} from '@dialog/organization-contracts';
import {
  getWidgetSettingsContract,
  updateWidgetSettingsContract,
  WidgetSettings,
} from '@dialog/search-contracts';

import { getOrgFromSession } from 'services/auth';
import { createAuthClient } from 'services/networking/client';
import { isUnderDocumentLimitSize, isUnderFileLimitSize } from 'utils/files';

import { refreshUserSession } from './auth';

const getOrganizationSlug = async (): Promise<string | undefined> => {
  try {
    const sessionResult = await Auth.currentSession();

    return getOrgFromSession(sessionResult) ?? undefined;
  } catch (error) {
    return undefined;
  }
};

export const createOrganization = async ({
  organizationName,
  ecommerce,
}: {
  organizationName: string;
  ecommerce?: boolean;
}): Promise<Organization> => {
  const response = await getAxiosRequest(
    createOrganizationContract,
    await createAuthClient(),
    {
      body: {
        organizationName,
        ecommerce,
      },
    },
  );

  return response.data;
};

export const createShopifyOrganization = async ({
  storeName,
  shopifyDomain,
  secret,
}: {
  storeName: string;
  shopifyDomain: string;
  secret: string;
}): Promise<ShopifyOrganization> => {
  const response = await getAxiosRequest(
    createShopifyOrganizationsContract,
    await createAuthClient(),
    {
      body: {
        storeName,
        secret,
        shopifyDomain,
      },
    },
  );

  return response.data;
};

export const existsOrganization = async ({
  organizationName,
}: {
  organizationName: string;
}): Promise<boolean> => {
  const response = await getAxiosRequest(
    organizationExistsContract,
    await createAuthClient(),
    {
      pathParameters: { organizationName },
    },
  );

  return response.data.exists;
};

const delay = (ms: number): Promise<void> => {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
};

export const getOrganization = async (): Promise<Organization> => {
  let organizationSlug = await getOrganizationSlug();

  // this should never happen, except during Organization creation process due to the async nature of some backend tasks - a bit crappy but it works
  if (organizationSlug === undefined) {
    let retry = 0;
    while (retry < 3 && organizationSlug === undefined) {
      console.log('Retrying to get organization slug');
      await refreshUserSession();
      await delay(1000);
      organizationSlug = await getOrganizationSlug();
      retry++;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (organizationSlug === undefined) {
      throw new Error('Organization slug is not defined');
    }
  }

  const response = await getAxiosRequest(
    getOrganizationContract,
    await createAuthClient(),
    {
      pathParameters: {
        organizationSlug,
      },
    },
  );

  return response.data;
};

export const updateLogo = async (
  base64: string,
  organizationSlug?: string,
): Promise<Organization> => {
  const slug = await getOrganizationSlug();

  const client = await createAuthClient();

  const response = await client.post<
    FromSchema<typeof uploadLogoContract.outputSchema>
  >(`/organization/${organizationSlug ?? slug ?? ''}/upload-logo`, base64, {
    headers: {
      'content-type': 'text/plain',
    },
  });

  return response.data;
};

export const updateOrganization = async ({
  widgetPosition,
  domainNames,
}: UpdateOrganizationRequest): Promise<Organization> => {
  const slug = await getOrganizationSlug();
  const response = await getAxiosRequest(
    updateOrganizationContract,
    await createAuthClient(),
    {
      pathParameters: {
        organizationSlug: slug ?? '',
      },
      body: { widgetPosition, domainNames },
    },
  );

  return response.data;
};

export const getUsersFromOrganization = async (
  organizationSlug: string,
): Promise<OrganizationUsers> => {
  const response = await getAxiosRequest(
    getUsersFromOrg,
    await createAuthClient(),
    {
      pathParameters: { organizationSlug },
    },
  );

  return response.data;
};

export const getProductOrInventoryUploadUrl = async ({
  organizationSlug,
}: {
  organizationSlug: string;
}): Promise<UploadProductInventoryResponse> => {
  const response = await getAxiosRequest(
    uploadProductInventoryContract,
    await createAuthClient(),
    {
      pathParameters: {
        organizationSlug,
      },
    },
  );

  return response.data;
};

export const uploadProductOrInventoryFile = async ({
  path,
  file,
  fields,
}: {
  path: string;
  file: File;
  fields: { [x: string]: unknown } | undefined;
}): Promise<{ status: StatusResponseTypes }> => {
  if (!isUnderFileLimitSize(file.size)) {
    throw new Error('EntityTooLarge');
  }

  const formData = new FormData();
  formData.append('Content-Type', file.type);
  if (fields !== undefined) {
    Object.keys(fields).forEach(key => {
      formData.append(key, fields[key] as string);
    });
  }
  formData.append('file', file);

  await axios({
    method: 'POST',
    data: formData,
    url: path,
  });

  return { status: SUCCESS };
};

export const saveOnboarding = async (
  data: OnboardingInformations,
): Promise<void> => {
  const slug = await getOrganizationSlug();
  const response = await getAxiosRequest(
    saveOnboardingContract,
    await createAuthClient(),
    {
      pathParameters: {
        organizationSlug: slug ?? '',
      },
      body: data,
    },
  );

  return response.data;
};

export const saveAiConfig = async (
  data: Omit<AiConfigWithFiles, 'metafields' | 'organizationSlug'>,
): Promise<void> => {
  const slug = await getOrganizationSlug();
  const response = await getAxiosRequest(
    updateAiConfigContract,
    await createAuthClient(),
    {
      pathParameters: {
        organizationSlug: slug ?? '',
      },
      body: data,
    },
  );

  return response.data;
};

export const updateWidgetSettings = async (
  data: WidgetSettings,
): Promise<void> => {
  const slug = await getOrganizationSlug();
  const response = await getAxiosRequest(
    updateWidgetSettingsContract,
    await createAuthClient(),
    {
      pathParameters: {
        organizationSlug: slug ?? '',
      },
      body: data,
    },
  );

  return response.data;
};

export const getMetafields = async ({
  organizationSlug,
}: {
  organizationSlug: string;
}): Promise<Metafields> => {
  const response = await getAxiosRequest(
    getMetafieldsContract,
    await createAuthClient(),
    {
      pathParameters: {
        organizationSlug,
      },
    },
  );

  return response.data;
};

export const getCollections = async ({
  organizationSlug,
}: {
  organizationSlug: string;
}): Promise<Collections> => {
  const response = await getAxiosRequest(
    getCollectionsContract,
    await createAuthClient(),
    {
      pathParameters: {
        organizationSlug,
      },
    },
  );

  return response.data;
};

export const getAiConfig = async ({
  organizationSlug,
}: {
  organizationSlug: string;
}): Promise<AiConfigWithFiles> => {
  const response = await getAxiosRequest(
    getAiConfigContract,
    await createAuthClient(),
    {
      pathParameters: {
        organizationSlug,
      },
    },
  );

  return response.data;
};

export const getWidgetSettings = async ({
  organizationSlug,
}: {
  organizationSlug: string;
}): Promise<WidgetSettings> => {
  const response = await getAxiosRequest(
    getWidgetSettingsContract,
    await createAuthClient(),
    {
      pathParameters: {
        organizationSlug,
      },
    },
  );

  return response.data;
};

export const getUploadDocumentsUrl = async ({
  organizationSlug,
  originalFileName,
}: {
  organizationSlug: string;
  originalFileName: string;
}): Promise<UploadDocumentUrlResponse> => {
  const response = await getAxiosRequest(
    getUploadDocumentUrlContract,
    await createAuthClient(),
    {
      queryStringParameters: {
        originalFileName,
      },
      pathParameters: {
        organizationSlug,
      },
    },
  );

  return response.data;
};

export const uploadDocuments = async ({
  path,
  file,
  fields,
}: {
  path: string;
  file: File;
  fields: { [x: string]: unknown } | undefined;
}): Promise<{ status: StatusResponseTypes }> => {
  if (!isUnderDocumentLimitSize(file.size)) {
    throw new Error('EntityTooLarge');
  }

  const formData = new FormData();
  formData.append('Content-Type', file.type);
  if (fields !== undefined) {
    Object.keys(fields).forEach(key => {
      formData.append(key, fields[key] as string);
    });
  }
  formData.append('file', file);

  await axios({
    method: 'POST',
    data: formData,
    url: path,
  });

  return { status: SUCCESS };
};

export const getProducts = async (
  organizationSlug: string,
): Promise<ProductsResults> => {
  const response = await getAxiosRequest(
    getProductsContract,
    await createAuthClient(),
    {
      pathParameters: {
        organizationSlug,
      },
    },
  );

  const products = await axios.get<ProductsResults>(response.data.fileUrl);

  return products.data;
};

export const getBillingData = async ({
  organizationSlug,
}: {
  organizationSlug: string;
}): Promise<BillingData> => {
  const response = await getAxiosRequest(
    getBillingDataContract,
    await createAuthClient(),
    {
      pathParameters: {
        organizationSlug,
      },
    },
  );

  return response.data;
};
export const getMetrics = async ({
  organizationSlug,
  type,
  period,
}: {
  organizationSlug: string;
  type: Metrics;
  period: MetricsPeriod;
}): Promise<MetricsOutput> => {
  const response = await getAxiosRequest(
    getMetricsContract,
    await createAuthClient(),
    {
      pathParameters: {
        organizationSlug,
      },
      queryStringParameters: {
        type,
        period,
      },
    },
  );

  return response.data;
};

export const getConversationsCluster = async ({
  organizationSlug,
}: {
  organizationSlug: string;
}): Promise<{ data: ConversationsClusterUrl[] }> => {
  const response = await getAxiosRequest(
    getConversationsClusterUrlContract,
    await createAuthClient(),
    {
      pathParameters: {
        organizationSlug,
      },
    },
  );

  const conversationClusterData = await axios.get<{
    data: ConversationsClusterUrl[];
  }>(response.data.fileUrl);

  return conversationClusterData.data;
};
