import { Product } from '@dialog/search-contracts';

import { ProductQuestions as ProductQuestionsI } from '../types';

export const enrichProductPageQuestion = (
  products: Product[] | undefined,
  productPageQuestions: ProductQuestionsI[],
): ProductQuestionsI[] => {
  if (products === undefined) return productPageQuestions;
  const result: ProductQuestionsI[] = [];

  const productMap = new Map(products.map(product => [product.id, product]));
  productPageQuestions.forEach(productPageQuestion => {
    const product = productMap.get(productPageQuestion.productId);

    if (product) {
      result.push({
        ...productPageQuestion,
        collections: product.collections,
        metadata: product.metadata,
        productType: product.productType,
      });

      productMap.delete(productPageQuestion.productId);
    }
  });

  productMap.forEach(product => {
    result.push({
      productName: product.title,
      productId: product.id,
      collections: product.collections,
      metadata: product.metadata,
      productType: product.productType,
      previewQuestions: [{ question: '' }, { question: '' }],
      questions: [{ question: '' }, { question: '' }],
    });
  });

  return result;
};
