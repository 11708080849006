import { update } from '@intercom/messenger-js-sdk';
import { useFlagsmith } from 'flagsmith/react';
import { usePostHog } from 'posthog-js/react';
import { createContext, useContext, useRef } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { WebSocketProvider } from '@dialog/design-system';
import {
  Organization,
  OrganizationPlans,
  OrganizationSyncStatus,
} from '@dialog/organization-contracts';

import { useAuth } from 'components/AuthProvider/AuthProvider';
import { RoutePaths } from 'config';
import { useGetOrganization } from 'hooks/organization';
import { shouldTrackUser } from 'utils/tracking';

interface organizationContext {
  organization: Organization;
}

export const OrganizationContext = createContext<organizationContext>({
  organization: {
    name: 'fakeName',
    slug: 'fakeName',
    apiKey: 'fakeApiKey',
    privateApiKey: 'fakeApiKey',
    currentSyncStatus: OrganizationSyncStatus.UNKNOWN,
    customAttributes: [],
    planPeriodEndDate: '',
    plan: OrganizationPlans.FREE,
    createdBy: '2023-06-15T13:08:22.000Z',
  },
});
export const useOrganization = (): organizationContext =>
  useContext(OrganizationContext);

export const OrganizationProvider = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}): JSX.Element => {
  const hasBeenRedirected = useRef<boolean>(false);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { saveSessionInState } = useAuth();
  const flagsmith = useFlagsmith();
  const postHog = usePostHog();
  const isAuthenticated = currentUser !== undefined;
  const getOrganization = useGetOrganization({
    enabled: isAuthenticated,
    onQuerySuccess: async data => {
      await saveSessionInState();
      update({
        company: data.name,
      });
      if (currentUser?.email !== undefined) {
        await flagsmith.identify(currentUser.email, {
          organization: data.slug,
          email: currentUser.email,
        });
      }
      if (
        currentUser?.email !== undefined &&
        shouldTrackUser(currentUser.email)
      ) {
        postHog.group('organization', data.slug, {
          name: data.name,
        });
      }

      if (data.hasFinishedOnboarding === false) {
        navigate(RoutePaths.ONBOARDING);
      }
    },
    onQueryError: () => {
      navigate(RoutePaths.CREATE_ORGANIZATION);
    },
  });

  if (getOrganization.data === undefined && !hasBeenRedirected.current) {
    hasBeenRedirected.current = true;
    <Navigate to="/login" />;
  }

  if (getOrganization.data === undefined) return <></>;

  return (
    <OrganizationContext.Provider
      value={{
        organization: getOrganization.data,
      }}
    >
      <WebSocketProvider
        baseWebSocketUrl={import.meta.env.VITE_WEBSOCKET_API_URL as string}
        apiKey={getOrganization.data.apiKey}
        sessionId={currentUser?.username as string}
      >
        {children}
      </WebSocketProvider>
    </OrganizationContext.Provider>
  );
};
